export const videoPlayerLegacyGql = `... on landingPageContent2023_videoPlayerLegacy_BlockType{
    typeHandle
    colors
    tabs{
      ... on tabs_BlockType{
        tabTitle: tabHeader
        autoplay
        videos{
          ... on productVideos_Asset{
            header: title
            videoId
            videoSource
            caption
            lpImageGridLandscape{
              srcsetWebp
              srcset
            }
          }
          ... on landingPageVideos_Asset{
            header: title
            videoId
            videoSource
            caption
            lpImageGridLandscape{
              srcsetWebp
              srcset
            }
          }
        }
      }
    }
  }`;
  