import { basicTextGql } from '../landing_page/basicTextGql.js';
import { blogAndPressReleasesGql } from '../landing_page/blogPressReleasesGql.js';
import { carouselGql } from '../landing_page/carouselGql.js';
import { contentIconCardsGql } from '../landing_page/contentIconCardsGql.js';
import { galleryGql } from '../landing_page/galleryGql.js';
import { galleryGridGql } from '../landing_page/galleryGridGql.js';
import { galleryHorizontalGql } from '../landing_page/galleryHorizontalGql.js';
import { featuredCardCardsGql } from '../landing_page/featuredCardCardsGql.js';
import { formGql } from '../landing_page/formGql.js';
import { imageCardGridGql } from '../landing_page/imageCardGridGql.js';
import { imageTextTabsGql } from '../landing_page/imageTextTabsGql.js';
import { interactiveDemoGql } from '../landing_page/interactiveDemoGql.js';
import { interactiveWebglDemoGql } from '../landing_page/interactiveWebglDemoGql.js';
import { linkedImageGridGql } from '../landing_page/linkedImageGridGql.js';
import { pageHeaderGql } from '../landing_page/pageHeaderGql.js';
import { quoteSliderGql } from '../landing_page/quoteSliderGql.js';
import { relatedProductsGql } from '../landing_page/relatedProductsGql.js';
import { soundCloudGql } from '../landing_page/soundCloudGql.js';
import { textContentCardsGql } from '../landing_page/textContentCardsGql.js';
import { textAccordionGql } from '../landing_page/textAccordionGql.js';
import { textIconListGql } from '../landing_page/textIconListGql.js';
import { textImageGql } from '../landing_page/textImageGql.js';
import { textImageGridGql } from '../landing_page/textImageGridGql.js';
import { textListGql } from '../landing_page/textListGql.js';
import { videoBannerGql } from '../landing_page/videoBannerGql.js';
import { videoPlayerGql } from '../landing_page/videoPlayerGql.js';
import { disclaimerFootnoteGql } from '../landing_page/disclaimerFootnoteGql.js';
import { heroBannerGql } from '../landing_page/heroBannerGql.js';
import { basicImageGql } from '../landing_page/basicImageGql.js';
import { locationFinderGql } from '../landing_page/locationFinderGql.js';

export const landingPageContentGql = `landingPageContent {
  ${basicTextGql}
  ${blogAndPressReleasesGql}
  ${carouselGql}
  ${contentIconCardsGql}
  ${galleryGql}
  ${galleryHorizontalGql}
  ${galleryGridGql}
  ${featuredCardCardsGql}
  ${formGql}
  ${imageCardGridGql}
  ${imageTextTabsGql}
  ${interactiveDemoGql}
  ${interactiveWebglDemoGql}
  ${linkedImageGridGql}
  ${pageHeaderGql}
  ${quoteSliderGql}
  ${relatedProductsGql}
  ${soundCloudGql}
  ${textIconListGql}
  ${textAccordionGql}
  ${textImageGql}
  ${textImageGridGql}
  ${textListGql}
  ${videoBannerGql}
  ${videoPlayerGql}
  ${textContentCardsGql}
  ${disclaimerFootnoteGql}
  ${heroBannerGql}
  ${basicImageGql}
  ${locationFinderGql}
}`;
