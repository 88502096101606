export const locationFinderGql = `... on landingPageContent_locationFinder_BlockType{
  typeHandle
   places {
      ... on excel_Asset {
        url
      }
      ... on pdfs_Asset {
        url
      }
   }
}`;
